import { makeAutoObservable } from 'mobx';
import { SuggestionType } from 'shared/enums';
import { IPostApprovalRowSuggestionDto, IPutApprovalRowSuggestionDto, IPutRowSuggestionSetAcceptedDto } from 'shared/interfaces';

class ApprovalRowSuggestionModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public suggestionType = SuggestionType.Update;

  public approvalRowId: number | null = null;

  public itemIds: number[] = [];

  public parameterId: number | null = null;

  public parameterName = '';

  public value: string | null = null;

  public parameterListValues: number[] = [];

  public defaultParameterListValues: number[] | null = null;

  public approvalId: number | null = null;

  public isAccepted = false;

  public approvalRowSuggestionId: number | null = null;

  public canEditList = false;

  public hasComments = false;

  public get postRowSuggestionDto(): IPostApprovalRowSuggestionDto {
    switch (this.suggestionType) {
      case SuggestionType.Create: {
        return {
          suggestionType: this.suggestionType,
          approvalRowId: null,
          itemIds: this.itemIds?.length !== 0 ? this.itemIds : null,
          parameterId: this.parameterId,
          value: this.value === '' ? null : this.value,
          parameterListValues: this.parameterListValues.length > 0 ? this.parameterListValues : null,
          approvalId: this.approvalId,
        };
      }

      case SuggestionType.Delete: {
        return {
          suggestionType: this.suggestionType,
          approvalRowId: this.approvalRowId,
          itemIds: null,
          parameterId: null,
          value: null,
          parameterListValues: null,
          approvalId: null,
        };
      }

      case SuggestionType.Update: {
        return {
          suggestionType: this.suggestionType,
          approvalRowId: this.approvalRowId,
          itemIds: null,
          parameterId: null,
          value: this.value === '' ? null : this.value,
          parameterListValues: this.parameterListValues.length > 0 ? this.parameterListValues : null,
          approvalId: null,
        };
      }
    }
  }

  public get putRowSuggestionStatusDto(): IPutRowSuggestionSetAcceptedDto | null {
    if (!this.approvalRowId) return null;

    return {
      id: this.approvalRowId,
      isAccepted: this.isAccepted,
    };
  }

  public get putRowSuggestionDto(): IPutApprovalRowSuggestionDto | null {
    return {
      value: this.value === '' ? null : this.value,
      parameterListValues: this.parameterListValues.length > 0 ? this.parameterListValues : null,
    };
  }

  public setApprovalId(approvalId: number) {
    this.approvalId = approvalId;
  }

  public setHasComments(hasComments: boolean) {
    this.hasComments = hasComments;
  }

  public setParameterId(paramId: number) {
    this.parameterId = paramId;
  }

  public setParameterName(parameterName: string) {
    this.parameterName = parameterName;
  }

  public setItemIds(itemIds: number[]) {
    this.itemIds = itemIds;
  }

  public setApprovalRowId(approvalRowId: number) {
    this.approvalRowId = approvalRowId;
  }

  public setSuggestionType(suggestionType: SuggestionType) {
    this.suggestionType = suggestionType;
  }

  public setApprovalRowSuggestionId(approvalRowSuggestionId: number) {
    this.approvalRowSuggestionId = approvalRowSuggestionId;
  }

  public setDefaultParameterListValueIds(defaultParameterListValueIds: number[] | null) {
    this.defaultParameterListValues = defaultParameterListValueIds;
  }

  public setParameterListValues(listId: number) {
    if (this.parameterListValues.includes(listId)) {
      const idx = this.parameterListValues.findIndex((f) => f === listId);
      if (idx !== -1) {
        this.parameterListValues.splice(idx, 1);
      }
    } else {
      this.parameterListValues.push(listId);
    }
  }

  public setParameterListValueIds(parameterListValueIds: number[]) {
    this.parameterListValues = parameterListValueIds;
  }

  public setValue(value: string | null) {
    this.value = value;
  }

  public setCanEditList(canEditList: boolean) {
    this.canEditList = canEditList;
  }

  public clear() {
    this.approvalRowId = null;
    this.suggestionType = SuggestionType.Update;
    this.parameterId = null;
    this.approvalId = null;
    this.itemIds = [];
    this.parameterListValues = [];
    this.defaultParameterListValues = null;
    this.value = null;
    this.parameterName = '';
    this.approvalRowSuggestionId = null;
    this.canEditList = false;
  }

  public clearValues() {
    this.parameterListValues = [];
    this.value = null;
  }
}

export default ApprovalRowSuggestionModel;
