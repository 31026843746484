import { BASE_PATH } from 'shared/constants/constants';

import { IGetCommentsDto } from 'shared/interfaces';
import HTTPService from './HttpService';

interface IPost {
  approvalRowId: number;
  parameterId: number;
  partialApprovalId: number;
  text: string;
}

interface IPut {
  partialApprovalId: number;
  text: string;
}

class ApprovalRowCommentsService extends HTTPService {
  constructor() {
    super(BASE_PATH.APPROVAL_ROWS_COMMENTS);
  }

  public getApprovalRowComments(id: number): Promise<IGetCommentsDto[]> {
    return this.GET('', { params: { approvalRowId: id } });
  }

  public postComment(body: IPost): Promise<number> {
    return this.POST('', body);
  }

  public putComment(id: number, body: IPut): Promise<void> {
    return this.PUT(`${id}`, body);
  }

  public deleteComment(id: number, partialApprovalId: number): Promise<void> {
    return this.DELETE(`${id}`, undefined, { params: { partialApprovalId } });
  }
}

export default new ApprovalRowCommentsService();
