import React, { useEffect, useState, KeyboardEvent } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Translator, commentStore } from 'stores';
import { DialogWrapper, IconButtonNew } from 'components';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import Input from 'components/UI/Input';
import { DialogActions, DialogContent, DialogTitle, Box, Stack, CircularProgress, Typography, Button, Divider, Tooltip } from '@mui/material';
import { useConfirm } from '../Confirm';
import { CommentMode, LangNames } from 'shared/enums';
import { IComment } from 'shared/interfaces';

import s from './CommentDialog.module.scss';

interface ICommentDialogProps {
  isOpenDialog: boolean;
  title: string;
}

const CommentDialog: React.FC<ICommentDialogProps> = (props) => {
  const { title, isOpenDialog } = props;
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);

  const confirm = useConfirm();

  useEffect(() => {
    return () => {
      commentStore.clearSelectedComment();
      commentStore.setCommentText('');
      commentStore.comments = [];
    };
  }, []);

  const onEditComment = (item: IComment) => {
    commentStore.selectedComment = { id: item.id, text: item.text };
    commentStore.setCommentText(item.text);
  };

  const onDeleteComment = (item: IComment) => {
    confirm.show(Translator.translate('common.commentDialog.confirmDeleteMessage'), Translator.translate('actions.delete')).then(() => {
      if (item.id) commentStore.deleteApprovalRowComment(item.id);
    });
  };

  const onCloseEditComment = () => {
    commentStore.clearSelectedComment();
    commentStore.setCommentText('');
  };

  const onPressEnter = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && commentStore.commentText.length) {
      commentStore.sendMessage();
    }
  };

  return (
    <DialogWrapper isCustom open={isOpenDialog} maxWidth="md" onClose={() => commentStore.setOpenModal(false)}>
      <DialogTitle>
        <Typography variant="h6" color="primary.dark">
          {Translator.translate('common.commentDialog.title')}
        </Typography>

        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ height: '24vh', maxHeight: '50vh', display: 'flex', flexDirection: 'column', gap: 4 }}>
        {commentStore.isLoading && (
          <Stack justifyContent="center" alignItems="center" sx={{ p: '0px 20px', minHeight: '200px', maxHeight: '200px' }}>
            <CircularProgress />
          </Stack>
        )}

        {!commentStore.isLoading &&
          (commentStore.filterComments.length > 0 ? (
            <Stack
              gap={4}
              sx={{
                width: '100%',
                p: 0,
                height: 'calc(100%-56px)',
                maxHeight: 'calc(100%-56px)',
                overflow: 'auto',
              }}>
              {commentStore.filterComments.map((item, index) => {
                return (
                  <Stack
                    paddingLeft={2}
                    gap={1}
                    key={item.id}
                    onMouseEnter={() => setHoveredRowId(item.id)}
                    onMouseLeave={() => setHoveredRowId(null)}>
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between" gap={5} minHeight={28}>
                      <Typography variant="body2" color="text.primary" sx={{ flex: 0.2 }}>
                        {index + 1}
                      </Typography>

                      <Tooltip
                        key="definition"
                        enterDelay={700}
                        enterNextDelay={700}
                        PopperProps={{
                          disablePortal: true,
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -6],
                              },
                            },
                          ],
                        }}
                        arrow
                        title={item.text}>
                        <Typography
                          className={s.truncated}
                          variant="body2"
                          color="text.primary"
                          sx={{ fontStyle: item.isArchived ? 'italic' : 'normal', flex: 4 }}>
                          {item.text}
                        </Typography>
                      </Tooltip>

                      <Typography variant="body2" color="text.primary" sx={{ flex: 2.1 }}>
                        {item.user.name}
                      </Typography>

                      <Typography variant="body2" color="text.primary" sx={{ flex: 1 }}>
                        {item.modifiedAt && commentStore.mode !== CommentMode.ApprovalRow
                          ? `${Translator.translate('common.commentDialog.changed')} ${dayjs(item.modifiedAt).format(
                              Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'
                            )}`
                          : dayjs(item.createdAt).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')}
                      </Typography>

                      <Stack sx={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: '5px' }}>
                        {hoveredRowId === item.id && (
                          <>
                            <IconButtonNew disabled={!item.isMyComment} onClick={() => onEditComment(item)}>
                              <Box
                                sx={{
                                  borderRadius: '4px',
                                  width: '100%',
                                  ':hover': {
                                    bgcolor: 'action.hover',
                                  },
                                  svg: {
                                    width: '16px',
                                    height: '16px',
                                  },
                                }}>
                                <EditPencilIcon />
                              </Box>
                            </IconButtonNew>
                            <IconButtonNew disabled={!item.isMyComment} onClick={() => onDeleteComment(item)}>
                              <Box
                                sx={{
                                  borderRadius: '4px',
                                  svg: {
                                    width: '16px',
                                    height: '16px',
                                  },
                                  width: '100%',
                                  ':hover': {
                                    bgcolor: 'action.hover',
                                  },
                                }}>
                                <TrashIcon />
                              </Box>
                            </IconButtonNew>
                          </>
                        )}
                      </Stack>
                    </Stack>
                    <Divider
                      sx={(theme) => ({
                        borderRightWidth: 2,
                        borderColor: theme.palette.background.default,
                      })}
                      orientation="horizontal"
                    />
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Typography variant="body2" color="text.primary">
              {Translator.translate('common.commentDialog.emptyComments')}
            </Typography>
          ))}
      </DialogContent>

      <DialogActions sx={{ px: 5 }}>
        <Stack width="100%" gap={3}>
          {commentStore.selectedComment.id && (
            <Stack gap={1}>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="caption" color="text.disabled">
                  {Translator.translate('actions.edit')}
                </Typography>

                <IconButtonNew onClick={onCloseEditComment}>
                  <CloseDialogIcon />
                </IconButtonNew>
              </Stack>

              <Typography variant="body2" color="text.primary">
                {commentStore.selectedComment.text}
              </Typography>
            </Stack>
          )}

          <Stack paddingTop={5} width="100%" gap={3} flexDirection="row" alignItems="flex-end">
            <Input
              size="small"
              variant="outlined"
              value={commentStore.commentText}
              sx={{ width: '100%' }}
              multiline
              autoFocus
              label={Translator.translate('common.commentDialog.inputLabel')}
              onChange={(value) => commentStore.setCommentText(value)}
              inputProps={{ maxLength: '256' }}
              onKeyDown={onPressEnter}
              shouldTriggerOnChange
            />

            <Button
              sx={{ minWidth: 'fit-content' }}
              variant="contained"
              onClick={commentStore.sendMessage}
              disabled={!commentStore.commentText.length}>
              {Translator.translate('actions.send')}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </DialogWrapper>
  );
};

export default observer(CommentDialog);
