export enum Roles {
  SystemAdmin = 'SystemAdmin',
  StandardAdmin = 'StandardAdmin',
  ProjectAdmin = 'ProjectAdmin',
  SpecificationSectionDeveloper = 'SpecificationSectionDeveloper',
  ProjectExecutor = 'ProjectExecutor',
  Default = 'Default',
}

export const RolesNames = new Map([
  [Roles.SystemAdmin, 'rolesNames.systemAdmin'],
  [Roles.StandardAdmin, 'rolesNames.standardAdmin'],
  [Roles.ProjectAdmin, 'rolesNames.projectAdmin'],
  [Roles.SpecificationSectionDeveloper, 'rolesNames.specificationSectionDeveloper'],
  [Roles.ProjectExecutor, 'rolesNames.projectExecutor'],
  [Roles.Default, 'rolesNames.default'],
]);

export enum LangNames {
  En = 'En',
  Ru = 'Ru',
}

export const LangFullNames = new Map([
  [LangNames.En, 'system.language.en'],
  [LangNames.Ru, 'system.language.ru'],
]);

export enum MergeGroups {
  Items,
  ParameterValues,
}

export const MergeGroupsNames = new Map([
  [MergeGroups.Items, 'merge.mergeGroupsNames.items'],
  [MergeGroups.ParameterValues, 'merge.mergeGroupsNames.parameterValues'],
]);

export enum MergeTabState {
  AddDel,
  Changed,
}

export const MergeTabStateNames = new Map([
  [MergeTabState.AddDel, 'merge.mergeTabStateNames.addDel'],
  [MergeTabState.Changed, 'merge.mergeTabStateNames.changed'],
]);

export const MergeAttributeNames = new Map([
  ['Name', 'merge.mergeAttributeNames.name'],
  ['IsMaster', 'merge.mergeAttributeNames.isMaster'],
  ['OrderNum', 'merge.mergeAttributeNames.orderNum'],
  ['Description', 'merge.mergeAttributeNames.description'],
  ['MeasurementUnit', 'merge.mergeAttributeNames.measurementUnit'],
  ['DataType', 'merge.mergeAttributeNames.dataType'],
]);

export enum ProjectType {
  Standard = 'Standard',
  Local = 'Local',
}

export enum ActionTableMode {
  Copy = 'Copy',
  Move = 'Move',
}

export enum SectionStructure {
  ItemsGroups = 'ItemsGroups',
  GroupsItems = 'GroupsItems',
}

export const SectionStructureNames = new Map([
  [SectionStructure.ItemsGroups, 'catalogs.specTemplates.sections.sectionStructureNames.itemsGroups'],
  [SectionStructure.GroupsItems, 'catalogs.specTemplates.sections.sectionStructureNames.groupsItems'],
]);

export enum MergeState {
  Added = 'Added',
  Deleted = 'Deleted',
  Updated = 'Updated',
}

export enum CommentMode {
  Item,
  Parameter,
  ApprovalRow,
}

export const CommentModeNames = new Map([
  [CommentMode.Item, 'comments.commentMode.Item'],
  [CommentMode.Parameter, 'comments.commentMode.Parameter'],
  [CommentMode.ApprovalRow, 'comments.commentMode.ApprovalRow'],
]);

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ProjectState {
  Proposed = 'Proposed',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  Approved = 'Approved',
}

export const ProjectStateNames = new Map([
  [ProjectState.Proposed, 'projects.projectStateNames.proposed'],
  [ProjectState.InProgress, 'projects.projectStateNames.inProgress'],
  [ProjectState.Canceled, 'projects.projectStateNames.canceled'],
  [ProjectState.Approved, 'projects.projectStateNames.approved'],
]);

export const ProjectTypeNames = new Map([
  [ProjectType.Standard, 'projects.projectTypeNames.standard'],
  [ProjectType.Local, 'projects.projectTypeNames.icp'],
]);

export enum MeasurementUnitType {
  Time = 'Time',
  Length = 'Length',
  Weight = 'Weight',
  Volume = 'Volume',
  Square = 'Square',
  Technical = 'Technical',
  Economic = 'Economic',
}

export enum XMLDocumentType {
  Template = 'Template',
  DefinitionInstance = 'DefinitionInstance',
}

export const MeasurementUnitTypeNames = new Map([
  [MeasurementUnitType.Time, 'catalogs.units.measurementUnitTypeNames.time'],
  [MeasurementUnitType.Length, 'catalogs.units.measurementUnitTypeNames.length'],
  [MeasurementUnitType.Weight, 'catalogs.units.measurementUnitTypeNames.weight'],
  [MeasurementUnitType.Volume, 'catalogs.units.measurementUnitTypeNames.volume'],
  [MeasurementUnitType.Square, 'catalogs.units.measurementUnitTypeNames.square'],
  [MeasurementUnitType.Technical, 'catalogs.units.measurementUnitTypeNames.technical'],
  [MeasurementUnitType.Economic, 'catalogs.units.measurementUnitTypeNames.economic'],
]);

export enum DataType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  List = 'List',
  Link = 'Link',
  Range = 'Range',
  DateTime = 'DateTime',
  File = 'File',
}

export const DataTypeNames = new Map([
  [DataType.String, 'params.dataTypeNames.string'],
  [DataType.Number, 'params.dataTypeNames.number'],
  [DataType.Boolean, 'params.dataTypeNames.boolean'],
  [DataType.List, 'params.dataTypeNames.list'],
  [DataType.Link, 'params.dataTypeNames.link'],
  [DataType.Range, 'params.dataTypeNames.range'],
  [DataType.DateTime, 'params.dataTypeNames.dateTime'],
  [DataType.File, 'params.dataTypeNames.file'],
]);

export enum DocumentSettingType {
  IsMultilineListValues = 'IsMultilineListValues',
  HideName = 'HideName',
}

export const DocumentSettingNames = new Map([
  [DocumentSettingType.IsMultilineListValues, 'params.documentSettingNames.isMultilineListValues'],
  [DocumentSettingType.HideName, 'params.documentSettingNames.hideName'],
]);

export enum ObjectLayer {
  Main = 1,
  Additional = 2,
}

export enum StateBoolean {
  False = 'false',
  Null = '',
  True = 'true',
}

export enum ConditionParamMode {
  DependsIf,
  DependsThen,
}

export const ConditionParamModeNames = new Map([
  [ConditionParamMode.DependsIf, 'params.conditionParamModeNames.dependsIf'],
  [ConditionParamMode.DependsThen, 'params.conditionParamModeNames.dependsThen'],
]);

export enum NestedLevel {
  First = 1,
  Second = 2,
  Third = 3,
}

export enum ParameterMode {
  Parameter,
  RelationSection,
}

export const ParameterModeNames = new Map([
  [ParameterMode.Parameter, 'params.parameterModeNames.parameter'],
  [ParameterMode.RelationSection, 'params.parameterModeNames.relationSection'],
]);

export enum ListValueMode {
  WatchList,
  EditList,
}

export enum DeleteClassMode {
  DeleteChecked,
  DeleteAll,
}

export const ObjectLayerNames = new Map([
  [ObjectLayer.Main, 'classes.objectLayerNames.main'],
  [ObjectLayer.Additional, 'classes.objectLayerNames.additional'],
]);

export enum Predicate {
  And = 'And',
  Or = 'Or',
}

export const PredicateName = new Map([
  [Predicate.And, 'И'],
  [Predicate.Or, 'ИЛИ'],
]);

export enum ChangeType {
  Added = 'Added',
  Updated = 'Updated',
  Deleted = 'Deleted',
}

export const ChangeTypeName = new Map([
  [ChangeType.Added, 'common.historyContent.changeTypeName.added'],
  [ChangeType.Updated, 'common.historyContent.changeTypeName.updated'],
  [ChangeType.Deleted, 'common.historyContent.changeTypeName.deleted'],
]);

export enum SelectorType {
  OKEICodeSelector = 'OKEICodeSelector',
  ParameterNameSelector = 'ParameterNameSelector',
  ValueSelector = 'ValueSelector',
}

export const SelectorTypeNames = new Map([
  [SelectorType.OKEICodeSelector, 'xml.selectorTypeNames.codeOKEI'],
  [SelectorType.ParameterNameSelector, 'xml.selectorTypeNames.parameterName'],
  [SelectorType.ValueSelector, 'xml.selectorTypeNames.value'],
]);

export enum AuditTables {
  Definitions = 1,
  ParameterGroups,
  Parameters,
  Classes,
  Items,
  ParameterValues,
  Specifications,
  SpecificationSections,
  SectionVariants,
  SectionVariantRows,
}

export enum SectionMode {
  Specification,
  Template,
}

export enum FileType {
  TXT,
  XLS,
  JPG,
  PPT,
  DOC,
  PDF,
  NotFound,
}

export enum PopupPosition {
  Right,
  Bottom,
}

export enum Reorder {
  Up,
  Down,
}
