import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
  Translator,
  approvalStore,
  approvalSuggestionRowStore,
  commentStore,
  parameterListValueStore,
  parameterValueStore,
  partialApprovalStore,
} from 'stores';
import { DialogWrapper, EditParameterListValue, IconButtonNew } from 'components';
import { useConfirm } from 'components/App/Confirm';
import CommentExistIcon from 'components/Icons/CommentExistIcon';
import CommentIcon from 'components/Icons/CommentIcon';
import { CommentMode } from 'shared/enums';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';

const ApprovalRowsListValueDialog: React.FC = () => {
  const confirm = useConfirm();

  useEffect(() => {
    return () => {
      approvalSuggestionRowStore.approvalSuggestionRow.clear();
      parameterValueStore.selectedParamValue.clear();

      runInAction(() => {
        parameterListValueStore.parameterListValues = [];
      });
    };
  }, []);

  const onCloseApprovalListValue = () => {
    approvalStore.setOpenApprovalDialog(false);
  };

  const onEditApprovalListValue = () => {
    if (approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues.length === 0) {
      confirm.show(Translator.translate('ui.suggestionRow.confirmEmptyListDeleteMessage'), Translator.translate('actions.delete')).then(() => {
        approvalSuggestionRowStore.approvalSuggestionRow.approvalRowSuggestionId &&
          approvalSuggestionRowStore.removeRowSuggestionApproval(approvalSuggestionRowStore.approvalSuggestionRow.approvalRowSuggestionId);
        onCloseApprovalListValue();
      });

      return;
    }

    if (!approvalSuggestionRowStore.approvalSuggestionRow.defaultParameterListValues) {
      approvalSuggestionRowStore.createRowSuggestionApproval();
    } else {
      approvalSuggestionRowStore.updateRowSuggestionApproval();
    }

    onCloseApprovalListValue();
  };

  const onClickListValue = (list: ParameterListValueModel) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(list.id!);
  };

  const onCheckParamListValues = (checkedIds: number[]) => {
    approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValueIds(checkedIds);
  };

  const onCommentApprovalRow = (rowId: number, rowParameterId: number, rowParameterName: string) => {
    commentStore.setMode(CommentMode.ApprovalRow);
    commentStore.setOpenModal(true);

    approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowId(rowId);
    approvalSuggestionRowStore.approvalSuggestionRow.setParameterName(rowParameterName);

    commentStore.setFilters(partialApprovalStore.selectedPartialApproval.id ?? undefined, rowParameterId ?? undefined);
    commentStore.getApprovalRowComments();
  };

  return (
    <DialogWrapper
      title={Translator.translate('objectValues.objectValueTable.editListValueDialog.title')}
      subTitle={`Параметр: ${approvalSuggestionRowStore.approvalSuggestionRow.parameterName}`}
      open={approvalStore.isApprovalDialogOpen}
      onClose={onCloseApprovalListValue}
      onSubmit={onEditApprovalListValue}
      onCancel={onCloseApprovalListValue}
      maxWidth="lg"
      hideActions={!approvalSuggestionRowStore.approvalSuggestionRow.canEditList}>
      <EditParameterListValue
        readOnly={!approvalSuggestionRowStore.approvalSuggestionRow.canEditList}
        onClickList={onClickListValue}
        onCheckParamListValues={onCheckParamListValues}
      />
      <IconButtonNew
        sx={{
          svg: {
            ...(!approvalSuggestionRowStore.approvalSuggestionRow.hasComments && { path: { stroke: '#7B68EE' } }),
          },
        }}
        tooltip={Translator.translate('actions.comments')}
        onClick={(e) => {
          e.stopPropagation();
          const { approvalRowId, parameterId, parameterName } = approvalSuggestionRowStore.approvalSuggestionRow;

          if (approvalRowId && parameterId) {
            onCommentApprovalRow(approvalRowId, parameterId, parameterName);
          }
        }}>
        {approvalSuggestionRowStore.approvalSuggestionRow.hasComments ? <CommentExistIcon /> : <CommentIcon />}
      </IconButtonNew>
    </DialogWrapper>
  );
};

export default observer(ApprovalRowsListValueDialog);
