import React, { useState, useEffect, KeyboardEvent } from 'react';
import { BaseTextFieldProps, TextField, InputAdornment, CircularProgress, Box } from '@mui/material';

import s from './Input.module.scss';

export interface IInputProps extends BaseTextFieldProps {
  canFocus?: boolean;
  onChange?: (value: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLElement>) => void;
  isLoading?: boolean;
  shouldTriggerOnChange?: boolean;
}

const Input: React.FC<IInputProps> = (props) => {
  const { type, isLoading, canFocus, onChange, onBlur, onKeyDown, shouldTriggerOnChange, ...rest } = props;

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(props.value as string);
  }, [props.value]);

  const transitionNewLine = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
      onChange && onChange(value);
    }

    if (e.code === 'Enter' && e.ctrlKey && !e.shiftKey) {
      setValue(`${value}\n`);
    }
  };

  const focus = (input: any) => {
    if (input !== null && canFocus) {
      input.focus();
    }
  };

  const onBlurAway = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    onChange && onChange(e.target.value);
    onBlur && onBlur;
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let val = e.target.value;
    if (type === 'number') {
      //удалить все, кроме точки, цифр
      const regexp = /[^0-9.,]/g;
      val = val.replace(regexp, '');
    }
    setValue(val);
    if (shouldTriggerOnChange) {
      onChange?.(val);
    }
  };

  return (
    <TextField
      {...rest}
      fullWidth
      value={value}
      autoComplete="off"
      inputRef={focus}
      onChange={onChangeValue}
      onBlur={onBlurAway}
      onKeyDown={onKeyDown}
      onKeyPress={transitionNewLine}
      InputProps={{
        endAdornment: isLoading && (
          <InputAdornment position="end">
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size="1.5rem" />
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Input;
