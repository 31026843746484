export const BASE_PATH = {
  OBJECT: '/classes',
  PARAMETER: '/parameters',
  PARAMETER_VALUE: '/parameter-values',
  DEFINITIONS: '/definitions',
  DEFINITIONS_SECTIONS: '/definition-sections',
  PROJECTS: '/projects',
  PARAMETER_GROUP: '/parameter-groups',
  MEASUREMENT_UNIT: '/measurement-units',
  XSD: '/xsd',
  XML_DOCUMENTS: '/xml-documents',
  ITEM: '/items',
  PARAMETER_LIST_VALUE: '/parameter-list-values',
  SPECIFICATIONS: '/specifications',
  SPECIFICATION_SECTIONS: '/specification-sections',
  SECTIONS: '/sections',
  SECTION_SETS: '/section-sets',
  SECTION_SET_COLLECTIONS: '/section-set-collections',
  SECTION_VARIANTS: '/section-variants',
  STAGES: '/stages',
  SECTION_VARIANT_ROWS: '/section-variant-rows',
  DOCUMENTS: '/documents',
  USERS: '/users',
  COMMENTS: '/comments',
  APPROVAL_ROWS_COMMENTS: '/approval-rows-comments',
  AUDIT: '/audit',
  CATEGORIES: '/categories',
  CONDITION: '/conditions',
  CLASSIFICATION_DICTIONARIES: '/classification-dictionaries',
  CLASSIFICATION_DICTIONARIES_VALUES: '/classification-dictionary-values',
  PARAMETER_BLACK_LIST: '/black-list',
  MERGE: '/merge',
  APPROVALS: '/approvals',
  APPROVAL_ROW_SUGGESTIONS: '/approval-row-suggestions',
  APPROVAL_ROWS: '/approval-rows',
  PARTIAL_APPROVALS: '/partial-approvals',
  INFORMATION_BLOCKS: 'information-block',
  GLOBAL_SETTINGS: 'global-settings',
};

export const DEFAULT_WIDTH_TREE = 364;

export const DEFAULT_WIDTH_GROUPS_MERGE = 300;

export const MAIN_PROJECT = 65;

export const HEADER_HEIGHT = 50;

export const LAYOUT_PADDING = 18;

export const PAGE_TITLE_HEIGHT = 32;

export const PAGE_TITLE_PADDING = 18;

export const HIDE_NAVIGATION__APPROVAL_ROW_WIDTH = 30;

export const MIN_NAVIGATION__APPROVAL = 300;
