import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { ApprovalRowCommentService, CommentService } from 'services';
import { usersStore, itemStore, parameterValueStore, approvalSuggestionRowStore, toastStore, definitionStore, Translator } from 'stores';
import { CommentMode, LangNames } from 'shared/enums';
import {
  ICommentParams,
  IComment,
  IGetCommentsDto,
  IItemCommentSummaryDto,
  IParameterValueCommentSummaryDto,
  IPostCommentDto,
  IPutCommentDto,
  ISelectComment,
} from 'shared/interfaces';

class CommentStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isOpenedDialog = false;

  public isOpenedComments = false;

  public filterPattern = '';

  public comments: IGetCommentsDto[] = [];

  public itemCommentsSummary: IItemCommentSummaryDto[] = [];

  public paramValueCommentsSummary: IParameterValueCommentSummaryDto[] = [];

  public selectedComment = { id: null, text: '' } as ISelectComment;

  public filters = <ICommentParams>{};

  public mode = CommentMode.Parameter;

  public commentText = '';

  public get itemCommentsSummaryForTable() {
    const data = this.itemCommentsSummary.map((el) => {
      return {
        ...el,
        createdAt: dayjs(el.createdAt).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'),
        userName: el.user.name,
      };
    });

    return data;
  }

  public get paramValueCommentsSummaryForTable() {
    const data = this.paramValueCommentsSummary.map((el) => {
      return {
        ...el,
        createdAt: dayjs(el.createdAt).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'),
        userName: el.user.name,
      };
    });

    return data;
  }

  public get filterComments(): IComment[] {
    const commentsData = this.comments.map((el) => {
      return { ...el, isMyComment: el.user.name === usersStore.getUserData().name };
    });

    return commentsData.filter((comment) => comment.text.toLocaleLowerCase().includes(this.filterPattern.toLocaleLowerCase()));
  }

  public get postCommentDto(): IPostCommentDto | null {
    if (!this.commentText.trim()) return null;

    return { ...this.filters, text: this.commentText.trim() };
  }

  public get postPutDto(): IPutCommentDto | null {
    if (!this.commentText.trim()) return null;

    return {
      text: this.commentText.trim(),
    };
  }

  public setFilterPattern(filter: string) {
    this.filterPattern = filter;
  }

  public setOpenModal(isOpen: boolean) {
    this.isOpenedDialog = isOpen;
  }

  public setOpenComment(isOpen: boolean) {
    this.isOpenedComments = isOpen;
  }

  public setMode(mode: CommentMode) {
    this.mode = mode;
  }

  public setCommentText(text: string) {
    this.commentText = text;
  }

  public clearSelectedComment() {
    this.selectedComment = { id: null, text: '' };
  }

  public setFilters(itemId?: number, parameterId?: number) {
    this.filters = {};

    switch (this.mode) {
      case CommentMode.Item:
        if (!itemId) {
          this.filters.itemId = itemStore.selectItem.id ?? undefined;
        } else {
          this.filters.itemId = itemId;
        }
        break;

      case CommentMode.Parameter:
        if (!parameterId || !itemId) {
          this.filters.parameterId = parameterValueStore.selectedParamValue.id ?? undefined;
          this.filters.itemId = itemStore.selectItem.id ?? undefined;
        } else {
          this.filters.parameterId = parameterId;
          this.filters.itemId = itemId;
        }
        break;

      case CommentMode.ApprovalRow:
        this.filters.approvalRowId = approvalSuggestionRowStore.approvalSuggestionRow.approvalRowId ?? undefined;
        this.filters.parameterId = parameterId;
        this.filters.itemId = itemId;
        break;
    }
  }

  public sendMessage() {
    if (this.selectedComment.id) {
      this.updateApprovalRowComment();
    } else {
      this.createApprovalRowComment();
    }
  }

  public async getAllComments(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await CommentService.getComments(this.filters);
      if (!result) return;

      result.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

      this.comments = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Comments`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getApprovalRowComments(): Promise<void> {
    if (!this.filters.approvalRowId) return;

    try {
      this.isLoading = true;
      const result = await ApprovalRowCommentService.getApprovalRowComments(this.filters.approvalRowId);
      if (!result) return;

      result.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

      this.comments = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ApprovalRowComments`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getCommentSummaryByDefinition(): Promise<void> {
    if (!definitionStore.currentDefinitionId) return;

    try {
      this.isLoading = true;

      const result = await CommentService.getCommentsSummary(definitionStore.currentDefinitionId);
      if (!result) return;

      this.itemCommentsSummary = result['itemComments'];
      this.paramValueCommentsSummary = result['parameterValueComments'];
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} CommentSummaryByDefinition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createApprovalRowComment(): Promise<void> {
    if (!this.filters.approvalRowId) return;

    try {
      this.isLoading = true;

      const result = await ApprovalRowCommentService.postComment({
        text: this.commentText,
        parameterId: this.filters.parameterId ?? 0,
        partialApprovalId: this.filters.itemId ?? 0,
        approvalRowId: this.filters.approvalRowId ?? 0,
      });
      if (!result) return;

      this.setCommentText('');
      this.getApprovalRowComments();

      approvalSuggestionRowStore.getApprovalRowList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} ApprovalRowComment`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createComment(): Promise<void> {
    if (!this.postCommentDto) return;

    try {
      this.isLoading = true;

      const result = await CommentService.postComment(this.postCommentDto);
      if (!result) return;
      this.setCommentText('');
      this.getAllComments();

      switch (this.mode) {
        case CommentMode.Item:
          return itemStore.getAllItems();

        case CommentMode.Parameter:
          {
            parameterValueStore.getParameterGroupValues();
          }

          break;
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Comment`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateApprovalRowComment(): Promise<void> {
    if (!this.selectedComment.id || !this.filters.itemId) return;

    try {
      this.isLoading = true;
      await ApprovalRowCommentService.putComment(this.selectedComment.id, { partialApprovalId: this.filters.itemId, text: this.commentText });
      this.setCommentText('');
      this.clearSelectedComment();
      this.getApprovalRowComments();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Comment`);
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteApprovalRowComment(commentId: number): Promise<void> {
    try {
      this.isLoading = true;
      if (this.filters.itemId) await ApprovalRowCommentService.deleteComment(commentId, this.filters.itemId);
      this.clearSelectedComment();
      await this.getApprovalRowComments();

      switch (this.mode) {
        case CommentMode.Item:
          {
            itemStore.getAllItems();
          }
          break;

        case CommentMode.Parameter:
          return parameterValueStore.getParameterGroupValues();

        case CommentMode.ApprovalRow:
          return this.comments.length === 0 ? approvalSuggestionRowStore.getApprovalRowList() : undefined;
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Comment`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new CommentStore();
