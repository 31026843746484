import { DependencyList, EffectCallback, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Translator, approvalStore, approvalSuggestionRowStore, partialApprovalStore } from 'stores';
import { Confirm, DownloadSpecificationButton, Select, Switch } from 'components';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Box, Button, Chip, Divider, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import {
  ApprovalAction,
  ApprovalUserRole,
  ApprovalUserRoleNames,
  PartialApprovalChangesType,
  PartialApprovalChangesTypeNames,
  PartialApprovalCommentFilterOption,
  PartialApprovalIteration,
  PartialApprovalIterationButtonsTitle,
  PartialApprovalIterationNames,
} from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import Utils from 'shared/utils/Utils';
import SuggestionDialog from './Components/SuggestionDialog';

const watchParam = (id: string | undefined, request: (id: number) => void): [EffectCallback, DependencyList] => [
  () => {
    if (id) {
      request.call(approvalStore, Number(id));
    }
  },
  [id],
];

const ApprovalRowsPage: React.FC = () => {
  const { approvalId, partialApprovalId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenUserRolesText, setOpenUserRolesText] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const selectStyles = {
    width: '220px',
    backgroundColor: '#F2F2F9',
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    borderRadius: '6px',
    margin: '6px',
  };

  const getDialogContent = () => {
    const { iteration } = partialApprovalStore.selectedPartialApproval;
    const basePath = 'approvals.approvalRowsPage';
    const dialogType = iteration === PartialApprovalIteration.Examination ? 'examinationConfirmDialog' : 'recheckConfirmDialog';

    return {
      okText: Translator.translate(`${basePath}.${dialogType}.okText`),
      cancelText: Translator.translate(`${basePath}.${dialogType}.cancelText`),
      message: Translator.translate(`${basePath}.${dialogType}.message`),
    };
  };

  const dialogContent = getDialogContent();

  const onSubmitIteration = async () => {
    let isSuccess: boolean;

    switch (partialApprovalStore.selectedPartialApproval.iteration) {
      case PartialApprovalIteration.Examination:
        isSuccess = await partialApprovalStore.updateIterationPartialApproval(partialApprovalStore.selectedPartialApproval.iteration);

        approvalSuggestionRowStore.getApprovalRowList();
        if (isSuccess) {
          approvalSuggestionRowStore.changeSuggestion(false);
          approvalSuggestionRowStore.clearConflictApprovalRow();
        }
        break;

      case PartialApprovalIteration.Execution:
        if (!isConfirmDialogOpen && approvalSuggestionRowStore.hasUnprocessedSuggestionWithoutConflict) {
          setIsConfirmDialogOpen(true);
          return;
        }

        isSuccess = await partialApprovalStore.updateIterationPartialApproval(partialApprovalStore.selectedPartialApproval.iteration);

        approvalSuggestionRowStore.getApprovalRowList();
        if (isSuccess) {
          approvalSuggestionRowStore.changeSuggestion(false);
          approvalSuggestionRowStore.clearConflictApprovalRow();
        }
        break;

      case PartialApprovalIteration.Recheck:
        if (!isConfirmDialogOpen && approvalSuggestionRowStore.hasUnprocessedSuggestionWithoutConflict) {
          setIsConfirmDialogOpen(true);
          return;
        }

        isSuccess = await partialApprovalStore.updateIterationPartialApproval(partialApprovalStore.selectedPartialApproval.iteration);

        approvalSuggestionRowStore.getApprovalRowList();
        if (isSuccess) {
          approvalSuggestionRowStore.changeSuggestion(false);
          approvalSuggestionRowStore.clearConflictApprovalRow();
        }
        break;

      case PartialApprovalIteration.Approval:
        await partialApprovalStore.updateIterationPartialApproval(partialApprovalStore.selectedPartialApproval.iteration);
        approvalStore.updateApprovalStatus(ApprovalAction.Approve).then(() => navigate(Routes.APPROVALS));
        break;
    }
  };

  useEffect(...watchParam(approvalId, approvalStore.getApprovalById));
  useEffect(...watchParam(partialApprovalId, partialApprovalStore.getPartialApprovalById));

  useEffect(() => {
    approvalSuggestionRowStore.getChangeSuggestionStorage();

    return () => {
      partialApprovalStore.selectedPartialApproval.clear();
    };
  }, []);

  useEffect(() => {
    if (approvalStore.selectedApproval.id && partialApprovalStore.selectedPartialApproval.id) {
      approvalStore.setPreSelectedApprovalId(approvalStore.selectedApproval.id, partialApprovalStore.selectedPartialApproval.id);
      location.pathname.includes(Routes.PARTIAL_APPROVALS_SETS)
        ? approvalStore.getPartialApprovalSets().then(() => approvalSuggestionRowStore.getApprovalRowList())
        : approvalSuggestionRowStore.getApprovalRowList();
    }
  }, [approvalStore.selectedApproval.id, partialApprovalStore.selectedPartialApproval.id]);

  useEffect(() => {
    approvalSuggestionRowStore.getChangeSuggestionStorage();
  }, [partialApprovalStore.selectedPartialApproval.iteration]);

  const userRolesText = `${Translator.translate('approvals.approvalRowsPage.userRolesText')}: ${partialApprovalStore.selectedPartialApproval.userRoles
    ?.map((role) => Translator.translate(ApprovalUserRoleNames.get(role as ApprovalUserRole)))
    .join(', ')}`;

  const getTitleButton = () => {
    const defaultTitle = partialApprovalStore.selectedPartialApproval.iteration
      ? Translator.translate(PartialApprovalIterationButtonsTitle.get(partialApprovalStore.selectedPartialApproval.iteration))
      : '';

    const noChangeTitle = Translator.translate('approvals.partialApprovalIterationButtonsTitle.approveNoChange');

    switch (partialApprovalStore.selectedPartialApproval.iteration) {
      case PartialApprovalIteration.Execution:
        return !approvalSuggestionRowStore.canGoToOnApprovalSkippingIterations ? defaultTitle : noChangeTitle;

      case PartialApprovalIteration.Examination:
        if (approvalSuggestionRowStore.canGoToOnApprovalSkippingIterations) {
          return noChangeTitle;
        }
        return approvalSuggestionRowStore.hasChangeSuggestion ? defaultTitle : noChangeTitle;
      case PartialApprovalIteration.Recheck:
        if (approvalSuggestionRowStore.hasChangeSuggestion) return defaultTitle;
        else return noChangeTitle;

      default:
        return defaultTitle;
    }
  };

  const setChangesTypeFilter = (type: PartialApprovalChangesType) => {
    approvalSuggestionRowStore.setChangesType(type);
    approvalSuggestionRowStore.getApprovalRowList();
  };

  const setCommentFilterOption = (option: PartialApprovalCommentFilterOption) => {
    approvalSuggestionRowStore.setCommentFilterOption(option);
    approvalSuggestionRowStore.getApprovalRowList();
  };

  const getSavedFilters = useCallback(() => {
    const data = localStorage.getItem('filters');
    return data ? JSON.parse(data) : {};
  }, []);

  useEffect(() => {
    if (!partialApprovalId) return;

    const savedFilters = getSavedFilters();
    const agreementFilters = savedFilters[partialApprovalId] || {};

    if (agreementFilters.changesTypeFilter) setChangesTypeFilter(agreementFilters.changesTypeFilter);
    if (agreementFilters.commentFilter) setCommentFilterOption(agreementFilters.commentFilter);
  }, [partialApprovalId]); // Загружаем фильтры только при смене страницы

  useEffect(() => {
    if (!partialApprovalId) return;

    const changesTypeFilter = approvalSuggestionRowStore.selectedChangesType;
    const commentFilter = approvalSuggestionRowStore.selectedCommentFilterOption;

    const savedFilters = getSavedFilters();

    const updatedFilters = {
      ...savedFilters,
      [partialApprovalId]: { changesTypeFilter, commentFilter },
    };

    localStorage.setItem('filters', JSON.stringify(updatedFilters));
  }, [approvalSuggestionRowStore.selectedChangesType, approvalSuggestionRowStore.selectedCommentFilterOption, partialApprovalId]); // Сохраняем при изменении фильтров или partialApprovalId

  useEffect(() => {
    return () => {
      approvalSuggestionRowStore.clearFilters(); // Очистка фильтров при размонтировании страницы
    };
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={6}>
        <Stack direction="row" alignItems="center" gap={5}>
          <Link style={{ display: 'flex', alignItems: 'center' }} to={`${Routes.APPROVALS}`}>
            <ArrowLeftIcon />
          </Link>

          {approvalStore.approvalSets.length > 0 && location.pathname.includes(Routes.PARTIAL_APPROVALS_SETS) ? (
            <>
              <Typography variant="subtitle1">{approvalStore.selectedPartialApprovalSets.map((el) => `${el.name} `)}</Typography>
              <Typography variant="caption" color="text.secondary">
                {`${Translator.translate('approvals.approvalRowsPage.infBlockAbbreviation')}:`}
                {approvalStore.selectedPartialApprovalSets.map((el) => `${el.informationBlock.name} `)}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">{partialApprovalStore.selectedPartialApproval.name}</Typography>

              <Typography variant="caption" color="text.secondary">{`${Translator.translate('approvals.approvalRowsPage.infBlockAbbreviation')}: ${
                partialApprovalStore.selectedPartialApproval.informationBlock.name
              }`}</Typography>
            </>
          )}

          <Tooltip
            open={isOpenUserRolesText}
            onOpen={() => {
              if (userRolesText.length >= 30) {
                setOpenUserRolesText(true);
              }
            }}
            onClose={() => setOpenUserRolesText(false)}
            title={userRolesText}>
            <Typography variant="caption" color="text.secondary" maxWidth="300px" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              {partialApprovalStore.selectedPartialApproval.userRoles.length > 0 ? userRolesText : 'Вы не участвуете в согласовании'}
            </Typography>
          </Tooltip>

          <Tooltip
            enterDelay={0}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -6],
                  },
                },
              ],
            }}
            arrow
            title={Translator.translate('approvals.approvalRowsPage.iteration')}>
            <Chip
              size="small"
              label={Translator.translate(PartialApprovalIterationNames.get(partialApprovalStore.selectedPartialApproval.iteration))}
            />
          </Tooltip>
        </Stack>

        <Stack direction="row" gap={3}>
          <DownloadSpecificationButton
            specificationId={approvalStore.selectedApproval.specification.id}
            name={approvalStore.selectedApproval.specification.name}
          />
          <Tooltip
            enterDelay={0}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -6],
                  },
                },
              ],
            }}
            arrow
            title={
              PartialApprovalIteration.Approved === partialApprovalStore.selectedPartialApproval.iteration
                ? Translator.translate('approvals.approvalRowsPage.alreadyApproved')
                : ''
            }>
            <Button
              sx={{ minWidth: 'fit-content' }}
              disabled={
                Utils.canEditApproval(
                  partialApprovalStore.selectedPartialApproval.iteration,
                  partialApprovalStore.selectedPartialApproval.userRoles
                ) || PartialApprovalIteration.Approved === partialApprovalStore.selectedPartialApproval.iteration
              }
              variant="contained"
              onClick={onSubmitIteration}>
              {getTitleButton()}
            </Button>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider
        sx={(theme) => ({
          my: 3,
          borderRightWidth: 2,
          borderColor: theme.palette.background.default,
        })}
        orientation="horizontal"
      />

      <Stack direction="row" mb={4} gap={8}>
        <Box flexGrow={1}>
          <Select
            fullWidth
            hideNotSelectItem
            label={Translator.translate('approvals.changeTypeSelectLabel')}
            sx={selectStyles}
            value={approvalSuggestionRowStore.selectedChangesType}
            options={approvalSuggestionRowStore.changesTypes.filter(
              (type) =>
                !(
                  partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Examination &&
                  type.name === Translator.translate(PartialApprovalChangesTypeNames.get(PartialApprovalChangesType.UnprocessedChanges))
                )
            )}
            onChange={(e: SelectChangeEvent<PartialApprovalChangesType>) => setChangesTypeFilter(e.target.value as PartialApprovalChangesType)}
            size="small"
          />
          <Select
            fullWidth
            hideNotSelectItem
            label={Translator.translate('approvals.commentFilterOptionSelectLabel')}
            sx={selectStyles}
            value={approvalSuggestionRowStore.selectedCommentFilterOption}
            options={approvalSuggestionRowStore.commentFilterOptions}
            onChange={(e: SelectChangeEvent<PartialApprovalCommentFilterOption>) =>
              setCommentFilterOption(e.target.value as PartialApprovalCommentFilterOption)
            }
            size="small"
          />
        </Box>
        <Switch
          label={Translator.translate('approvals.approvalRowsPage.hideApproval')}
          secondaryColor
          labelPlacement="end"
          checked={approvalSuggestionRowStore.isHideApproval}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => approvalSuggestionRowStore.setHideApproval(event.target.checked)}
        />
        {/* AB - не используется, спрятано согласно задаче #76414 */}
        {/* <Switch
          label={Translator.translate('approvals.approvalRowsPage.showAllSuggestions')}
          secondaryColor
          labelPlacement="end"
          checked={approvalSuggestionRowStore.isShowAllSuggestions}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            approvalSuggestionRowStore.setShowAllSuggestions(event.target.checked);
            approvalSuggestionRowStore.getApprovalRowList();
          }}
        /> */}

        <Button
          disabled={
            !partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
            partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck
          }
          variant="text"
          startIcon={
            <Box
              sx={(theme) => ({
                svg: {
                  fill:
                    !partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
                    partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck
                      ? theme.palette.text.disabled
                      : theme.palette.primary.main,
                },
              })}>
              <PlusIconForButton />
            </Box>
          }
          onClick={() => approvalSuggestionRowStore.setSuggestionDialogDialogOpen(true)}>
          {Translator.translate('approvals.approvalRowsPage.addValueButtonTitle')}
        </Button>
      </Stack>

      {approvalSuggestionRowStore.isSuggestionDialogOpen && <SuggestionDialog />}

      <Confirm
        okText={dialogContent.okText}
        cancelText={dialogContent.cancelText}
        content={dialogContent.message}
        visible={isConfirmDialogOpen}
        onOk={() => {
          onSubmitIteration();
          setIsConfirmDialogOpen(false);
        }}
        onCancel={() => setIsConfirmDialogOpen(false)}
        isSuccess
      />

      <Outlet />
    </>
  );
};

export default observer(ApprovalRowsPage);
